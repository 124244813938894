

import index from './modules/index'

export default [
    {
        path: 'canalInvite',
        component: () => import(/*webpackChunkName: 'canalInvite'*/ '@layout/frame'),
        children: [
            ...index,
        ]
    },
]