export default {
    path: 'list',
    component: () => import(/*webpackChunkName: 'user-userInfo'*/ '@views/user/views/userInfo/list.vue'),
    meta: {
        title: '用户信息管理',
        topNav: true,
    },
    children: [
        // {
        //     path:"list",
        //     component: () => import(/*webpackChunkName: 'user-userInfo'*/ '@views/user/views/userInfo/list.vue'),
        //     meta: {
        //         title: '用户',
        //         topNav: true,
        //     },
        // },

    ]
}
