import settItem from './modules/settItem'
import settItemChild from './modules/settItemChild'
import hcgSettItem from './modules/hcgSettItem'
import ShengNeiSetItem from './modules/ShengNeiSetItem'
import settCheck from './modules/settCheck'

export default [
    {
        path: 'settmg',
        component: () => import(/*webpackChunkName: 'settmg'*/ '@layout/frame'),
        children: [
            settItem,
            settItemChild,
            hcgSettItem,
            ShengNeiSetItem,
            settCheck,
        ]
    },
]
