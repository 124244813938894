export default {
    path: 'settShengNei',
    redirect: 'settShengNei/list',
    component: () => import(/*webpackChunkName: 'settmg-settShengNei'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'settmg-settShengNei'*/ '@views/settmg/views/ShengNeiSetItem/list.vue'),
            meta: {
                title: '省内订单结算',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'settmg-settShengNei'*/ '@views/settmg/views/ShengNeiSetItem/detail.vue'),
            meta: {
                title: '清单详情',
                topNav: true,
            },
        },
        {
            path:"settList",
            component: () => import(/*webpackChunkName: 'settmg-settShengNei'*/ '@views/settmg/views/ShengNeiSetItem/settList.vue'),
            meta: {
                title: '结算确认',
                topNav: true,
            },
        },

    ]
}
