export default{
    install: function(Vue){
        Vue.component('my-btn', function(){return import(/*webpackChunkName: 'coms-sys'*/ './my-btn')});
        Vue.component('my-tag', function(){return import(/*webpackChunkName: 'coms-sys'*/ './my-tag')});
        Vue.component('btn-builder', function(){return import(/*webpackChunkName: 'coms-sys'*/ './btn-builder')});

        Vue.component('action-row', function(){return import(/*webpackChunkName: 'coms-sys'*/ './action-row')});
        Vue.component('info-tip', function(){return import(/*webpackChunkName: 'coms-sys'*/ './info-tip')});

        Vue.component('state-list', function(){return import(/*webpackChunkName: 'coms-sys'*/ './state-list')});
        Vue.component('btn-list', function(){return import(/*webpackChunkName: 'coms-sys'*/ './btn-list')});
        Vue.component('city-cascader', function(){return import(/*webpackChunkName: 'coms-sys'*/ './city-cascader')});
        Vue.component('upload-new', function(){return import(/*webpackChunkName: 'coms-sys'*/ './upload-new.vue')});
        Vue.component('upload-new-userinfo', function(){return import(/*webpackChunkName: 'coms-sys'*/ './upload-new-userinfo.vue')});
        Vue.component('lazy-list', function(){return import(/*webpackChunkName: 'coms-sys'*/ './lazy-list.vue')});
        Vue.component('my-checkbox-group', function(){return import(/*webpackChunkName: 'coms-sys'*/ './my-checkbox-group.vue')});
    }
}
