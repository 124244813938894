
import user from './modules/user'
import role from './modules/role'
import dept from './modules/dept'

export default [
    {
        path: 'sys',
        component: () => import(/*webpackChunkName: 'sys'*/ '@layout/frame'),
        children: [
            user,
            role,
            dept
        ]
    },
]
