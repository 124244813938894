export default {
    path: 'wininfoProject',
    redirect: 'wininfoProject/list',
    component: () => import( '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import('@views/wininfo/views/wininfoProject/list.vue'),
            meta: {
                title: '中标信息',
                topNav: true,
            },
        },
        // {
        //     path:"detail",
        //     component: () => import( '@views/myproject/views/wininfoProject/detail.vue'),
        //     meta: {
        //         title: '项目详情',
        //         topNav: true,
        //     },
        // },
        // {
        //     path:"test",
        //     component: () => import('@views/myproject/views/wininfoProject/test.vue'),
        //     meta: {
        //         title: 'test',
        //         topNav: true,
        //     },
        // },

        
    ]
}