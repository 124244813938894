import Vue from 'vue'
import router from '@router/index'
import store from '@store/index'

import storage from '@js/storage.js'
import md5 from '@js/md5.js'

export default {
    // namespaced: true,
    state: {
        system: 'views',
        navDropDown: [
            {
                text: '修改密码',
                icon: 'el-icon-lock',
                command: () => {
                    router.push({
                        path: '/sys/user/password/form'
                    });
                }
            },
            {
                text: '退出登录',
                icon: 'el-icon-switch-button',
                command: () => {
                    // Vue.prototype.$post(`${store.getters.userUrl}/logout`, () => {
                    //     router.push('/login');
                    //     store.commit('setUser', {});
                    //     store.commit('setSystem', {});
                    // });
                    router.push('/login');
                    store.commit('setUser', {});
                    store.commit('setSystem', {});
                    store.commit('setUserToken', {});
                }
            },
  
        ],
        navList: storage.getSession('menu'),
    },
    getters: {

    },
    mutations: {
        setState: function(state, n){
            mixin(n, state, true);
        },
        setNavList(state, n) {
            state.navList = n;
            storage.setSession('menu', n);
        },


    },
    actions: {
        login({commit, getters, state, dispatch}, form) {
            let sendForm = clone(form)
            if(sendForm.pwd){
                sendForm.pwd = md5(sendForm.pwd);
            }
            
            Vue.prototype.$post(`${store.getters.userUrl}/login`, sendForm , function (data, res) {
                store.commit('setUser', data);

                store.commit('setSystem', {
                    type: state.system,
                });

                if(form.autoLogin == true){
                    store.commit('setUserToken', {
                        expirein:data.expirein,
                        token:data.token,
                    });
                }
                
                dispatch('getMenu');
                dispatch('getcitylist');

                router.replace('/sys/index');
            },true);
        },
        queryUserInfo({state, dispatch}, requery) {
            // if(!requery) {
            //     var userInfo = store.getters.user;
            //     if(Object.keys(userInfo).length) {
            //         dispatch('updateNavDropDown', userInfo);
            //         return;
            //     };
            // };

            // Vue.prototype.$get(`${store.getters.ageUrl}/getuserinfo`, data => {
            //     store.commit('setUser', data);
            //     store.commit('setSystem', {
            //         type: state.system,
            //         api: store.getters.ageUrl
            //     });

            //     dispatch('updateNavDropDown', data);
            //     dispatch('getMenu');
            // });
        },
        getMenu({commit, state}) {
            Vue.prototype.$get(`${store.getters.sysUrl}/menu/list`, data => {
                commit('setNavList', data);
            });
        },
        getcitylist({commit, state}) {
            Vue.prototype.$get(`${store.getters.comUrl}/city/getarea`,{
                parentid:"all"
            }, data => {
                commit('setCitylist', data);
            });
        },

        isAutoLogin({commit, getters, state, dispatch}) {
            store.commit('setUser', storage.getLocal('user'));

            store.commit('setSystem', {
                type: state.system,
            });
            dispatch('getMenu');
            dispatch('getcitylist');
            router.replace('/sys/index');
        },

        updateNavDropDown({commit, state}, {user}) {
            // commit('setNavDropDown', []);
        },
    }
}
