import wininfoProject from './modules/wininfoProject'
// import hcgOrder from './modules/hcgOrder'

export default [
    {
        path: 'wininfo',
        component: () => import(/*webpackChunkName: 'ordermg'*/ '@layout/frame'),
        children: [
            wininfoProject,
            // hcgOrder
        ]
    },
]