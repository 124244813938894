import tenderProject from './modules/tenderProject'
import projectAudit from './modules/projectAudit'

export default [
    {
        path: 'myproject',
        component: () => import(/*webpackChunkName: 'myproject'*/ '@layout/frame'),
        children: [
            tenderProject,
            projectAudit,
        ]
    },
]