import userInfo from './modules/userInfo'


export default [
    {
        path: 'userInfo',
        component: () => import(/*webpackChunkName: 'settmg'*/ '@layout/frame'),
        children: [
            userInfo,
        ]
    },
]