
import partner from './modules/partner'

export default [
    {
        path: 'partnermg',
        component: () => import(/*webpackChunkName: 'partnermg'*/ '@layout/frame'),
        children: [
            partner
        ]
    },
]
