import index from './modules/index'


export default [
    {
        path: 'sys',
        component: () => import(/*webpackChunkName: 'sys'*/ '@layout/frame'),
        children: [
            index
        ]
    },
]