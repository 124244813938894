export default [
    {
        path: 'settle',
        component: () => import(/*webpackChunkName: 'settle'*/ '@layout/frame'),
        children: [
            {
                path: 'product',
                redirect: 'product/list',
                component: () => import(/*webpackChunkName: 'settle-product'*/ '@layout/empty'),
                children: [
                    {
                        path:"list",
                        component: () => import(/*webpackChunkName: 'settle-product'*/ '@views/settle/views/product/list.vue'),
                        meta: {
                            title: '结算产品管理',
                            topNav: true,
                        },
                    },
                ]
            },
            {
                path: 'rule',
                redirect: 'rule/list',
                component: () => import(/*webpackChunkName: 'settle-rule'*/ '@layout/empty'),
                children: [
                    {
                        path:"list",
                        component: () => import(/*webpackChunkName: 'settle-rule'*/ '@views/settle/views/rule/list.vue'),
                        meta: {
                            title: '结算规则管理',
                            topNav: true,
                        },
                    },
                ]
            },
            {
                path: 'order',
                redirect: 'order/list',
                component: () => import(/*webpackChunkName: 'settle-order'*/ '@layout/empty'),
                children: [
                    {
                        path:"list",
                        component: () => import(/*webpackChunkName: 'settle-order'*/ '@views/settle/views/order/list.vue'),
                        meta: {
                            title: '订单查询',
                            topNav: true,
                        },
                    },
                    {
                        path:"detail",
                        component: () => import(/*webpackChunkName: 'settle-order'*/ '@views/settle/views/order/detail.vue'),
                        meta: {
                            title: '订单详情',
                            topNav: true,
                        },
                    },
                    {
                        path:"list-settle",
                        component: () => import(/*webpackChunkName: 'settle-order'*/ '@views/settle/views/order/list-settle.vue'),
                        meta: {
                            title: '订单结算',
                            topNav: true,
                        },
                    },
                ]
            },
            {
                path: 'bill',
                redirect: 'bill/list',
                component: () => import(/*webpackChunkName: 'settle-bill'*/ '@layout/empty'),
                children: [
                    {
                        path:"list",
                        component: () => import(/*webpackChunkName: 'settle-bill'*/ '@views/settle/views/bill/list.vue'),
                        meta: {
                            title: '结算查询',
                            topNav: true,
                        },
                    },
                    {
                        path:"detail",
                        component: () => import(/*webpackChunkName: 'settle-bill'*/ '@views/settle/views/bill/detail.vue'),
                        meta: {
                            title: '结算详情',
                            topNav: true,
                        },
                    },
                    {
                        path:"list-settle",
                        component: () => import(/*webpackChunkName: 'settle-bill'*/ '@views/settle/views/bill/list-settle.vue'),
                        meta: {
                            title: '结算记录',
                            topNav: true,
                        },
                    },
                ]
            },
        ],
    },
];
