export default {
    path: 'role',
    redirect: 'role/list',
    component: () => import(/*webpackChunkName: 'sys-role'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'sys-role'*/ '@views/sys/views/role/list'),
            meta: {
                title: '角色管理',
                topNav: true,
            },
        },
    ]
}