import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '../store/index'
import CONFIG from '../config'

export default function(router) {
    router.beforeEach(function(to, from, next){
        NProgress.start();

        //设置面包屑
        var title = to.meta.title;
        document.title = `${title ? (title + '·') : ''}${CONFIG.router['title']}`;
        // store.commit('setBread', to.meta.bread || []);
        store.commit('updateBread', {to, from});

        //设置tag导航
        store.dispatch('routeToggleTagNav', to);

        // let allList = ['/login', '/sys/index', '/ordermg/hcgOrder/list', '/settmg/hcgSettItem/list', '/settmg/hcgSettItem/list', '/settmg/settShengNei/list', '/settmg/settShengNei/list', '/settmg/settShengNei/list', '/ordermg/guaOrder/list', '/settmg/settItem/list', '/settmg/settItemChild/list', '/channel/channelInfo/list', '/myproject/tenderProject/list', '/userInfo/list', '/myproject/projectAudit/list', '/sys/user/list', '/sys/dept/list', '/sys/role/list']
        // let roeterList = ['/login','/sys/index']
        // if(store.state.views.navList.length>0){
        //     store.state.views.navList.forEach(function(item){
        //         if(!!item.url && item.url.indexOf('?') != -1){
        //             roeterList.push((item.url).split('?')[0]);
        //         }else{
        //             roeterList.push(item.url);
        //         }
        //         if(!!item.children && item.children.length > 0){
        //             item.children.forEach(function(childrenItem){
        //                 if(childrenItem.url.indexOf('?') != -1){
        //                     roeterList.push((childrenItem.url).split('?')[0]);
        //                 }else{
        //                     roeterList.push(childrenItem.url);
        //                 }
    
        //             })
        //         }
        //     });
        //     roeterList = roeterList.filter(item => item)
        // }

        // if(roeterList.includes(to.path.split('?')[0])){
        //     next()
        // }else if (allList.includes(to.path.split('?')[0]) == true && roeterList.includes(to.path.split('?')[0]) == false){
        //    return ShowMsg('暂无权限访问', 'error');
        // }else {
        //     next()
        // }

        next();
    });

    router.afterEach(function(){
        NProgress.done();
    });
}
