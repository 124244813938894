exports.vue = {
    devServer: {
        port: '5010',
        open: true,
        proxy: {
            '/': {
                target: 'http://192.168.1.7:8183',
                changeOrigin: true,
                pathRewrite: {
                    '^/': '/'
                }
            }
        },
    }
}

exports.project = {
    'project-type': 'pc',

    assetsDir: 'static',
    
    ui: {
        element: true,
        mint: false
    },

    router: {
        'base-name': '',
        title: '渠道结算系统'
    },

    //ajax类型axios或jquery，用法相同
    ajax: 'jquery',

    sentry: false,

    cnzz: {
        id: '',
        show: 'pic1'
    }
}