export default {
    path: 'guaOrder',
    redirect: 'guaOrder/list',
    component: () => import(/*webpackChunkName: 'ordermg-guaOrder'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'ordermg-guaOrder'*/ '@views/ordermg/views/guaOrder/list.vue'),
            meta: {
                title: '保函订单查询',
                topNav: true,
            },
        },
        
    ]
}