export default {
    path: 'settItemChild',
    redirect: 'settItemChild/list',
    component: () => import(/*webpackChunkName: 'settmg-settItemChild'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'settmg-settItemChild'*/ '@views/settmg/views/settItemChild/list.vue'),
            meta: {
                title: '我的结算清单',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'settmg-settItemChild'*/ '@views/settmg/views/settItemChild/detail.vue'),
            meta: {
                title: '清单详情',
                topNav: true,
            },
        },
    ]
}
