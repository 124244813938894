export default {
    path: 'tenderProject',
    redirect: 'tenderProject/list',
    component: () => import(/*webpackChunkName: 'myproject-tenderProject'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'myproject-tenderProject'*/ '@views/myproject/views/tenderProject/list.vue'),
            meta: {
                title: '我的项目',
                topNav: true,
            },
        },
        {
            path:"addProject",
            component: () => import(/*webpackChunkName: 'myproject-tenderProject'*/ '@views/myproject/views/tenderProject/addProject.vue'),
            meta: {
                title: '添加项目信息',
                topNav: true,
            },
        },
        {
            path:"sectionList",
            component: () => import(/*webpackChunkName: 'myproject-tenderProject'*/ '@views/myproject/views/tenderProject/sectionList.vue'),
            meta: {
                title: '标段列表',
                topNav: true,
            },
        },
        {
            path:"fileList",
            component: () => import(/*webpackChunkName: 'myproject-tenderProject'*/ '@views/myproject/views/tenderProject/fileList.vue'),
            meta: {
                title: '招标文件',
                topNav: true,
            },
        },
        
    ]
}