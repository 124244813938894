export default {
    path: 'hcgOrder',
    redirect: 'hcgOrder/list',
    component: () => import(/*webpackChunkName: 'ordermg-hcgOrder'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'ordermg-hcgOrder'*/ '@views/ordermg/views/hcgOrder/list.vue'),
            meta: {
                title: '惠采购订单查询',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'ordermg-hcgOrder'*/ '@views/ordermg/views/hcgOrder/detail.vue'),
            meta: {
                title: '订单详情',
                topNav: true,
            },
        },
        
    ]
}