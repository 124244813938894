export default {
    path: 'dept',
    redirect: 'dept/list',
    component: () => import(/*webpackChunkName: 'sys-dept'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'sys-dept'*/ '@views/sys/views/dept/list'),
            meta: {
                title: '部门管理',
                topNav: true,
            },
        },
    ]
}