export default {
    // twUrl: '/pms/teamwork',
    // sysUrl: '/pms'
    indexUrl: '/bhtong',
    comUrl:'/bhtong/common',
    sysUrl: '/bhtong/sysmg',
    partnerUrl: '/bhtong/channel',
    orderUrl:'/bhtong/ordermg',
    settUrl:'/bhtong/settmg',
    desktop:'/bhtong/desktop',
    userUrl:'/bhtong/user',
    userInfoUrl:'/bhtong/userInfo',
    myprojectUrl:'/bhtong/myproject',
    canalInviteUrl:'/bhtong/canalInvite',
}
