export default {
    path: 'hcgSettItem',
    redirect: 'hcgSettItem/list',
    component: () => import(/*webpackChunkName: 'settmg-hcgSettItem'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'settmg-hcgSettItem'*/ '@views/settmg/views/hcgSettItem/list.vue'),
            meta: {
                title: '惠采购订单结算',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'settmg-hcgSettItem'*/ '@views/settmg/views/hcgSettItem/detail.vue'),
            meta: {
                title: '清单详情',
                topNav: true,
            },
        },
    ]
}
