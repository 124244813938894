export default {
    path: 'projectAudit',
    redirect: 'projectAudit/list',
    component: () => import(/*webpackChunkName: 'myproject-projectAudit'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'myproject-projectAudit'*/ '@views/myproject/views/projectAudit/list.vue'),
            meta: {
                title: '项目审核管理',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'myproject-projectAudit'*/ '@views/myproject/views/projectAudit/detail.vue'),
            meta: {
                title: '项目详情',
                topNav: true,
            },
        },
        {
            path:"test",
            component: () => import(/*webpackChunkName: 'myproject-projectAudit'*/ '@views/myproject/views/projectAudit/test.vue'),
            meta: {
                title: 'test',
                topNav: true,
            },
        },

        
    ]
}