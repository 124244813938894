export default {
    path: 'partner',
    redirect: 'partner/list',
    component: () => import(/*webpackChunkName: 'partnermg-partner'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'partnermg-partner'*/ '@views/partnermg/views/partner/list.vue'),
            meta: {
                title: '单位信息管理',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'partnermg-partner'*/ '@views/partnermg/views/partner/detail.vue'),
            meta: {
                title: '详情',
                topNav: true,
            },
        },
    ]
}