import storage from '@js/storage.js'

export default {
    state: {
        userToken: storage.getLocal('userToken') || {}
    },
    mutations: {
        setState: function(state, n){
            mixin(n, state, true);
        },
        setUserToken(state, n) {
            state.userToken = n;
            storage.setLocal('userToken', n);
        }
    }
}
