export default {
    path: 'settItem',
    redirect: 'settItem/list',
    component: () => import(/*webpackChunkName: 'settmg-settItem'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'settmg-settItem'*/ '@views/settmg/views/settItem/list.vue'),
            meta: {
                title: '保函结算管理',
                topNav: true,
            },
        },
        {
            path:"detail",
            component: () => import(/*webpackChunkName: 'settmg-settItem'*/ '@views/settmg/views/settItem/detail.vue'),
            meta: {
                title: '清单详情',
                topNav: true,
            },
        },
        {
            path:"childdetail",
            component: () => import(/*webpackChunkName: 'settmg-settItem'*/ '@views/settmg/views/settItem/childDetail.vue'),
            meta: {
                title: '清单详情',
                topNav: true,
            },
        },
    ]
}
