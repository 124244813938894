import guaOrder from './modules/guaOrder'
import hcgOrder from './modules/hcgOrder'

export default [
    {
        path: 'ordermg',
        component: () => import(/*webpackChunkName: 'ordermg'*/ '@layout/frame'),
        children: [
            guaOrder,
            hcgOrder
        ]
    },
]