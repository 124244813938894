export default {
    path: 'user',
    redirect: 'user/list',
    component: () => import(/*webpackChunkName: 'sys-user'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'sys-user'*/ '@views/sys/views/user/list'),
            meta: {
                title: '用户管理',
                topNav: true,
            },
        },
        {
            path:"password/form",
            component: () => import(/*webpackChunkName: 'sys-user'*/ '@views/sys/views/user/password/form.vue'),
            meta: {
                title: '修改密码',
                topNav: true,
            },
        },
    ]
}