import storage from '@js/storage.js'

export default {
    state: {
        citylist: storage.getSession('citylist') || []
    },
    mutations: {
        setState: function(state, n){
            mixin(n, state, true);
        },
        setCitylist(state, n) {
            state.citylist = n;
            storage.setSession('citylist', n);
        }
    }
}
