export default {
    path: 'settCheck',
    redirect: 'settCheck/list',
    component: () => import(/*webpackChunkName: 'settmg-settCheck'*/ '@layout/empty'),
    children: [
        {
            path:"list",
            component: () => import(/*webpackChunkName: 'settmg-settCheck'*/ '@views/settmg/views/settCheck/list.vue'),
            meta: {
                title: '结算对账',
                topNav: true,
            },
        },

    ]
}
