export default [
    {
        path: 'list',
        component: () => import(/*webpackChunkName: 'canalInvite-list'*/ '@views/canalInvite/views/list.vue'),
        meta: {
            title: '渠道邀请管理',
            topNav: true,
        },
    }
]

